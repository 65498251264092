import React from "react";
import { graphql } from "gatsby";
import { PostProvider } from "../context/PostContext"

import ComponentComposer from "../components/ComponentComposer";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SEO from "../components/seo";

const IndexPage = ({ data }) => (
  <>
	<Layout>
		<SEO
			seoBody={data.wordpressPage.yoast_head}
			title={data.wordpressPage.title}
			description={data.wordpressPage.excerpt}
		/>
		<Hero {...data.wordpressPage.acf.hero} />
		<PostProvider value={data.allWordpressPost}>
			{data.wordpressPage.children.map((item, index) => {
				const previousComponent = index > 0 ? data.wordpressPage.children[index - 1] : null;
				return (
					<ComponentComposer key={index} componentName={item.__typename} previousComponentName={previousComponent?.__typename} id={item.id} index={index} {...item} />
				)
			})}
		</PostProvider>
	</Layout>
  </>
)

export default IndexPage;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "homepage" }) {
      yoast_head
      title
      excerpt
      acf {
        hero {
          image_alignment
          headline
          copy
          cta {
            target
            title
            url
          }
          background_image {
            alt_text
            localFile {
              url
            }
          }
          foreground_texture
		  background_texture
        }
      }
      children {
        __typename
        id
        ...CtaBlockFragment
        ...FormBlockFragment
        ...ImageStackFragment
        ...LogoGridFragment
        ...MediaBlockFragment
        ...QuoteFragment
        ...RelatedContentFragment
        ...ToutFragment
        ...FullScreenToutFragment
        ...KeyStatsFragment
        ...TextBlockFragment
        ...BioBlockFragment
        ...FeedFragment
		...FeaturedPostsFragment
      }
    }
    allWordpressPost {
      edges {
        node {
          acf {
            external_link
          }
          title
          tags {
            name
            slug
          }
          categories {
            name
            slug
          }
          featured_media {
            alt_text
            localFile {
              url
            }
          }
          slug
          excerpt
          wordpress_id
        }
      }
    }
  }
`
